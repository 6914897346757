<template>
  <a-image
    v-if="isSmall"
    :src="imageUrl"
    :alt="$options.consts.altText"
    v-bind="linkProps"
    class="mocked-events-banner__image small"
  />
  <a-image
    v-else-if="isLarge"
    :src="imageUrl"
    :alt="$options.consts.altText"
    v-bind="linkProps"
    :aspect-ratio="$options.consts.aspectRatios.homepageMockedEventBanner"
    class="mocked-events-banner__image large"
  />
</template>

<script>
import { PROP_TYPES, propValidator } from '@/utils/validators'
import { getLinkToFmBucketFile } from '@/plugins/helper'
import { aspectRatios } from 'enums/aspectRatios'

export const SIZE = {
  SMALL_320x250: 'SMALL_320x250',
  LARGE_970x250: 'LARGE_970x250'
}

export default {
  name: 'AMockedEventsBanner',
  props: {
    size: propValidator([PROP_TYPES.STRING], true, null, v =>
      Object.values(SIZE).includes(v)
    )
  },
  consts: {
    aspectRatios,
    altText: 'Colorful UTIP promotional banner'
  },
  data() {
    return {
      imageUrlBySize: {
        [SIZE.SMALL_320x250]: getLinkToFmBucketFile(
          'utip_banner_v2_320x250.png'
        ),
        [SIZE.LARGE_970x250]: getLinkToFmBucketFile(
          'utip_banner_v2_970x250.png'
        )
      },
      linkPropsBySize: {
        [SIZE.SMALL_320x250]: {
          url: `https://www.utip.org/?utm_source=fm_en&utm_medium=website_mobile&utm_campaign=brokerage_320x50`,
          openInNewTab: true,
          ariaLabel: 'Access the full report'
        },
        [SIZE.LARGE_970x250]: {
          url: `https://www.utip.org/?utm_source=fm_en&utm_medium=website_desktop&utm_campaign=brokerage_970x250`,
          openInNewTab: true,
          ariaLabel: 'Access the full report'
        }
      }
    }
  },
  computed: {
    imageUrl() {
      return this.imageUrlBySize[this.size]
    },
    linkProps() {
      return this.linkPropsBySize[this.size]
    },
    isSmall() {
      return this.size === SIZE.SMALL_320x250
    },
    isLarge() {
      return this.size === SIZE.LARGE_970x250
    }
  }
}
</script>

<style scoped lang="scss">
.mocked-events-banner__image {
  &.small {
    width: 320px;
    height: 250px;
  }

  &.large {
    width: 100%;
  }
}
</style>
